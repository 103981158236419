@import 'mixins/padding';


/* @group footer */
.footer {
  @include vertical-padding(45px);

  &__copyright {
    color: #bbb;
    transition: color .3s ease;

    &:hover {
      color: #333;
    }
  }

  &__navigation {
    text-align: right;

    &-item {
      color: #bbb;
      margin-left: 10px;
      transition: color .3s ease;

      &_active {
        font-weight: bold;
        color: #333;
      }

      &:hover {
        color: #333;
      }
    }
  }
}
/* @end */
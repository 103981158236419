@import '../mixins/font';


/* @group animation*/
@-webkit-keyframes colorful-animation {
  0% {
    background-position: 0 90%
  }
  50% {
    background-position: 100% 11%
  }
  100% {
    background-position: 0 90%
  }
}

@-moz-keyframes colorful-animation {
  0% {
    background-position: 0 90%
  }
  50% {
    background-position: 100% 11%
  }
  100% {
    background-position: 0 90%
  }
}

@keyframes colorful-animation {
  0% {
    background-position: 0 90%
  }
  50% {
    background-position: 100% 11%
  }
  100% {
    background-position: 0 90%
  }
}

/* @end */

/* @group colorful block */
.colorful {
  &_animated {
    background-size: 600% 600%;
    -webkit-animation: colorful-animation 60s ease infinite;
    -moz-animation: colorful-animation 60s ease infinite;
    animation: colorful-animation 60s ease infinite;
  }

  &_theme {
    &_1 {
      background-image: -webkit-linear-gradient(45deg, hsla(206, 98%, 50%, 1) 0%, hsla(206, 98%, 50%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(185, 93%, 44%, 1) 10%, hsla(185, 93%, 44%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(185, 91%, 41%, 1) 10%, hsla(185, 91%, 41%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(174, 94%, 49%, 1) 100%, hsla(174, 94%, 49%, 0) 70%);

      -webkit-background-clip: border-box, border-box, border-box, border-box;
      -webkit-background-origin: padding-box, padding-box, padding-box, padding-box;
      -webkit-background-size: auto, auto, auto, auto;

      background-attachment: scroll, scroll, scroll, scroll;
      background-clip: border-box, border-box, border-box, border-box;
      background-color: rgba(0, 0, 0, 0);
      background-image: linear-gradient(45deg, rgb(2, 144, 253) 0%, rgba(2, 144, 253, 0) 70%), linear-gradient(135deg, rgb(7, 199, 217) 10%, rgba(7, 199, 217, 0) 80%), linear-gradient(225deg, rgb(9, 184, 200) 10%, rgba(9, 184, 200, 0) 80%), linear-gradient(315deg, rgb(7, 243, 219) 100%, rgba(7, 243, 219, 0) 70%);
      background-origin: padding-box, padding-box, padding-box, padding-box;
      background-size: auto, auto, auto, auto;
    }

    &_1-1 {
      background-image: linear-gradient(45deg, hsla(206, 98%, 50%, 1) 0%, hsla(206, 98%, 50%, 0) 70%),
      linear-gradient(135deg, hsla(185, 93%, 44%, 1) 10%, hsla(185, 93%, 44%, 0) 80%),
      linear-gradient(225deg, hsla(185, 91%, 41%, 1) 10%, hsla(185, 91%, 41%, 0) 80%),
      linear-gradient(315deg, hsla(174, 94%, 49%, 1) 100%, hsla(174, 94%, 49%, 0) 70%);
    }

    &_2 {
      background-image: -webkit-linear-gradient(45deg, hsla(211, 100%, 47%, 1) 0%, hsla(211, 100%, 47%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(202, 94%, 49%, 1) 10%, hsla(202, 94%, 49%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(214, 93%, 42%, 1) 10%, hsla(214, 93%, 42%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(111, 94%, 49%, 1) 100%, hsla(111, 94%, 49%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(211, 100%, 47%, 1) 0%, hsla(211, 100%, 47%, 0) 70%),
      linear-gradient(135deg, hsla(202, 94%, 49%, 1) 10%, hsla(202, 94%, 49%, 0) 80%),
      linear-gradient(225deg, hsla(214, 93%, 42%, 1) 10%, hsla(214, 93%, 42%, 0) 80%),
      linear-gradient(315deg, hsla(111, 94%, 49%, 1) 100%, hsla(111, 94%, 49%, 0) 70%);
    }

    &_3 {
      background-image: -webkit-linear-gradient(45deg, hsla(225, 97%, 41%, 1) 0%, hsla(225, 97%, 41%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(125, 91%, 49%, 1) 10%, hsla(125, 91%, 49%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(176, 98%, 47%, 1) 10%, hsla(176, 98%, 47%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(224, 97%, 47%, 1) 100%, hsla(224, 97%, 47%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(225, 97%, 41%, 1) 0%, hsla(225, 97%, 41%, 0) 70%),
      linear-gradient(135deg, hsla(125, 91%, 49%, 1) 10%, hsla(125, 91%, 49%, 0) 80%),
      linear-gradient(225deg, hsla(176, 98%, 47%, 1) 10%, hsla(176, 98%, 47%, 0) 80%),
      linear-gradient(315deg, hsla(224, 97%, 47%, 1) 100%, hsla(224, 97%, 47%, 0) 70%);
    }

    &_4 {
      background-image: -webkit-linear-gradient(45deg, hsla(256, 65%, 35%, 1) 0%, hsla(256, 65%, 35%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(187, 94%, 43%, 1) 10%, hsla(187, 94%, 43%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(232, 52%, 50%, 1) 10%, hsla(232, 52%, 50%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(190, 100%, 59%, 1) 100%, hsla(190, 100%, 59%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(256, 65%, 35%, 1) 0%, hsla(256, 65%, 35%, 0) 70%),
      linear-gradient(135deg, hsla(187, 94%, 43%, 1) 10%, hsla(187, 94%, 43%, 0) 80%),
      linear-gradient(225deg, hsla(232, 52%, 50%, 1) 10%, hsla(232, 52%, 50%, 0) 80%),
      linear-gradient(315deg, hsla(190, 100%, 59%, 1) 100%, hsla(190, 100%, 59%, 0) 70%);
    }

    &_5 {
      background-image: -webkit-linear-gradient(45deg, hsla(137, 99%, 48%, 1) 0%, hsla(137, 99%, 48%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(173, 94%, 48%, 1) 10%, hsla(173, 94%, 48%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(211, 100%, 45%, 1) 10%, hsla(211, 100%, 45%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(279, 100%, 49%, 1) 100%, hsla(279, 100%, 49%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(137, 99%, 48%, 1) 0%, hsla(137, 99%, 48%, 0) 70%),
      linear-gradient(135deg, hsla(173, 94%, 48%, 1) 10%, hsla(173, 94%, 48%, 0) 80%),
      linear-gradient(225deg, hsla(211, 100%, 45%, 1) 10%, hsla(211, 100%, 45%, 0) 80%),
      linear-gradient(315deg, hsla(279, 100%, 49%, 1) 100%, hsla(279, 100%, 49%, 0) 70%);
    }

    &_6 {
      background-image: -webkit-linear-gradient(45deg, hsla(199, 100%, 48%, 1) 0%, hsla(199, 100%, 48%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(143, 100%, 50%, 1) 10%, hsla(143, 100%, 50%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(274, 97%, 49%, 1) 10%, hsla(274, 97%, 49%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(299, 0%, 47%, 1) 100%, hsla(299, 0%, 47%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(199, 100%, 48%, 1) 0%, hsla(199, 100%, 48%, 0) 70%),
      linear-gradient(135deg, hsla(143, 100%, 50%, 1) 10%, hsla(143, 100%, 50%, 0) 80%),
      linear-gradient(225deg, hsla(274, 97%, 49%, 1) 10%, hsla(274, 97%, 49%, 0) 80%),
      linear-gradient(315deg, hsla(299, 0%, 47%, 1) 100%, hsla(299, 0%, 47%, 0) 70%);
    }

    &_7 {
      background-image: -webkit-linear-gradient(45deg, hsla(286, 94%, 44%, 1) 0%, hsla(286, 94%, 44%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(333, 91%, 41%, 1) 10%, hsla(333, 91%, 41%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(315, 92%, 43%, 1) 10%, hsla(315, 92%, 43%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(191, 100%, 45%, 1) 100%, hsla(191, 100%, 45%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(286, 94%, 44%, 1) 0%, hsla(286, 94%, 44%, 0) 70%),
      linear-gradient(135deg, hsla(333, 91%, 41%, 1) 10%, hsla(333, 91%, 41%, 0) 80%),
      linear-gradient(225deg, hsla(315, 92%, 43%, 1) 10%, hsla(315, 92%, 43%, 0) 80%),
      linear-gradient(315deg, hsla(191, 100%, 45%, 1) 100%, hsla(191, 100%, 45%, 0) 70%);
    }

    &_8 {
      background-image: -webkit-linear-gradient(45deg, hsla(288, 99%, 47%, 1) 0%, hsla(288, 99%, 47%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(24, 99%, 48%, 1) 10%, hsla(24, 99%, 48%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(282, 93%, 41%, 1) 10%, hsla(282, 93%, 41%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(226, 94%, 47%, 1) 100%, hsla(226, 94%, 47%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(288, 99%, 47%, 1) 0%, hsla(288, 99%, 47%, 0) 70%),
      linear-gradient(135deg, hsla(24, 99%, 48%, 1) 10%, hsla(24, 99%, 48%, 0) 80%),
      linear-gradient(225deg, hsla(282, 93%, 41%, 1) 10%, hsla(282, 93%, 41%, 0) 80%),
      linear-gradient(315deg, hsla(226, 94%, 47%, 1) 100%, hsla(226, 94%, 47%, 0) 70%);
    }

    &_9 {
      background-image: -webkit-linear-gradient(45deg, hsla(260, 93%, 50%, 1) 0%, hsla(260, 93%, 50%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(2, 99%, 48%, 1) 10%, hsla(2, 99%, 48%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(297, 91%, 44%, 1) 10%, hsla(297, 91%, 44%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(282, 93%, 50%, 1) 100%, hsla(282, 93%, 50%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(260, 93%, 50%, 1) 0%, hsla(260, 93%, 50%, 0) 70%),
      linear-gradient(135deg, hsla(2, 99%, 48%, 1) 10%, hsla(2, 99%, 48%, 0) 80%),
      linear-gradient(225deg, hsla(297, 91%, 44%, 1) 10%, hsla(297, 91%, 44%, 0) 80%),
      linear-gradient(315deg, hsla(282, 93%, 50%, 1) 100%, hsla(282, 93%, 50%, 0) 70%);
    }

    &_10 {
      background-image: -webkit-linear-gradient(45deg, hsla(15, 94%, 45%, 1) 0%, hsla(15, 94%, 45%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(313, 93%, 44%, 1) 10%, hsla(313, 93%, 44%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(21, 96%, 46%, 1) 10%, hsla(21, 96%, 46%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(344, 92%, 45%, 1) 100%, hsla(344, 92%, 45%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(15, 94%, 45%, 1) 0%, hsla(15, 94%, 45%, 0) 70%),
      linear-gradient(135deg, hsla(313, 93%, 44%, 1) 10%, hsla(313, 93%, 44%, 0) 80%),
      linear-gradient(225deg, hsla(21, 96%, 46%, 1) 10%, hsla(21, 96%, 46%, 0) 80%),
      linear-gradient(315deg, hsla(344, 92%, 45%, 1) 100%, hsla(344, 92%, 45%, 0) 70%);
    }

    &_11 {
      background-image: -webkit-linear-gradient(45deg, hsla(359, 79%, 21%, 1) 0%, hsla(359, 79%, 21%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(0, 77%, 47%, 1) 10%, hsla(0, 77%, 47%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(0, 100%, 46%, 1) 10%, hsla(0, 100%, 46%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(323, 100%, 32%, 1) 100%, hsla(323, 100%, 32%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(359, 79%, 21%, 1) 0%, hsla(359, 79%, 21%, 0) 70%),
      linear-gradient(135deg, hsla(0, 77%, 47%, 1) 10%, hsla(0, 77%, 47%, 0) 80%),
      linear-gradient(225deg, hsla(0, 100%, 46%, 1) 10%, hsla(0, 100%, 46%, 0) 80%),
      linear-gradient(315deg, hsla(323, 100%, 32%, 1) 100%, hsla(323, 100%, 32%, 0) 70%);
    }

    &_12 {
      background-image: -webkit-linear-gradient(45deg, hsla(63, 99%, 41%, 1) 0%, hsla(63, 99%, 41%, 0) 70%),
      -webkit-linear-gradient(315deg, hsla(65, 93%, 43%, 1) 10%, hsla(65, 93%, 43%, 0) 80%),
      -webkit-linear-gradient(225deg, hsla(58, 94%, 46%, 1) 10%, hsla(58, 94%, 46%, 0) 80%),
      -webkit-linear-gradient(135deg, hsla(279, 97%, 42%, 1) 100%, hsla(279, 97%, 42%, 0) 70%);
      background-image: linear-gradient(45deg, hsla(63, 99%, 41%, 1) 0%, hsla(63, 99%, 41%, 0) 70%),
      linear-gradient(135deg, hsla(65, 93%, 43%, 1) 10%, hsla(65, 93%, 43%, 0) 80%),
      linear-gradient(225deg, hsla(58, 94%, 46%, 1) 10%, hsla(58, 94%, 46%, 0) 80%),
      linear-gradient(315deg, hsla(279, 97%, 42%, 1) 100%, hsla(279, 97%, 42%, 0) 70%);
    }

    &_20 {
      background-image: linear-gradient(319deg, #a24bcf, #4b79cf, #4bc5cf);
    }
  }
}

/* @end */
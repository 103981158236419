@import '../mixins/padding';


/* @group contact */
.social-media-block {
  @include vertical-padding(10px);

  text-align: center;
  font-size: 2em;

  &__item {
    color: #c2c2c2;
    transition: .3s ease color;

    &:hover {
      color: #666;
    }
  }
}
/* @end */
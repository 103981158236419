/* @group layout */

/* @group grid-system */
@import 'variables/bootstrap-grid';

@import '../../../bower_components/bootstrap/scss/bootstrap-grid';
@import '../../../bower_components/bootstrap/scss/mixins/visibility';
@import '../../../bower_components/bootstrap/scss/utilities/visibility';
/* @end */

.section {
  border-bottom: 1px solid #eee;
  width: 100%;
}
/* @end */
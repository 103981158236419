@mixin animation($delay: false, $duration: false) {
  @if $delay {
    animation-delay: #{$delay}ms;
  }

  @if $duration {
    animation-duration: #{$duration}ms;
  }
}

.animated_chain-1-order {
  // blog/homepage, projects/homepage, music/artist
  &_1 { @include animation(200, false); }
  &_2 { @include animation(400, 1400); }
  &_3 { @include animation(700, 600); }
}

.animated_chain-2-order {
  // main/contact, music/lowthree
  &_1 { @include animation(false, 2000); }
  &_2 { @include animation(750, 2000); }
  &_3 { @include animation(1500, 2000); }
  &_4 { @include animation(2000, 2000); }
}

.animated_chain-3-order {
  // main/homepage
  &_1 { @include animation(false, 3000); }
  &_2 { @include animation(1950, 3000); }
  &_3 { @include animation(6000, 3000); }
}

.animated_chain-4-order {
  // main/md
  &_1 { @include animation(false, 300); }
  &_2 { @include animation(100, 500); }
  &_3 { @include animation(200, 400); }
  &_4 { @include animation(300, 400); }
}
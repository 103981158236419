@import '../variables/themes';


@mixin theme-color() {
  /* @group themes */
  @each $themeName, $themeColor in $themes {
    & {
      /* @group #{$themeName} theme */
      .html_theme_#{$themeName} & {
        color: #{$themeColor};
        //transition: .3s ease color;
        &:hover {
          color: darken($themeColor, 10%);
        }
      }
      /* @end */
    }
  }
  /* @end */
}
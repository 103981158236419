@import '../variables/dotudash-logo';


@mixin header-dark() {
  color: #fff;
  text-shadow: 1px 0 0 #fff;
}

// logo
// `DIE` is okay in this case, eg. `original` is not a dotudash theme,
// so there may be some logic
$header-logo-map:
  ( 'black':      $dotudash-logo-black-40x40
  , 'blue':       $dotudash-logo-blue-40x40
  , 'green':      $dotudash-logo-green-40x40
  , 'lightblue':  $dotudash-logo-lightblue-40x40
  , 'purple':     $dotudash-logo-purple-40x40
  , 'red':        $dotudash-logo-red-40x40

  , 'original':   $dotudash-logo-original-40x40
  // TODO
  , 'orange':     $dotudash-logo-original-40x40
  , 'java':       $dotudash-logo-original-40x40
  );

@mixin header-logo-base() {
  $sprite: map_get($header-logo-map, 'original');
  $sprite-image: nth($sprite, 9);

  background: url('images/' + $sprite-image);
  @include sprite-position($sprite); // default
}

@mixin header-logo-theme($themeName) {
  $sprite: map_get($header-logo-map, $themeName);
  @include sprite-position($sprite);
}
@mixin font-light() {
  color: #fff;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, .2);
}

@mixin font-dark() {
  color: #333;
  text-shadow: 1px 1px 0 #fff;
}

@mixin font-from-background-color($backgroundColor) {
  @if (lightness($backgroundColor) > 60) {
    @include font-dark;
  } @else {
    @include font-light;
  }
}
@import 'variables/themes';


/* @group base */
* {
  box-sizing: border-box;
  outline: none;
}

.html {
  font-size: 12px;
  height: 100%;
}

.body {
  margin: 0;

  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, sans-serif;

  color: #444;
  text-shadow: 1px 1px 0 #fff;

  &_swag_on {
    border-top: 4px solid;
    @each $themeName, $themeColor in $themes {
      .html_theme_#{$themeName} & {
        border-top-color: $themeColor;
      }
    }
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.hr {
  width: 100%;
  margin: 5px 0;

  border: none;
  border-top: 1px solid rgba(0, 0, 0, .1);
  border-bottom: 1px solid rgba(255, 255, 255, .1);

  &_faded {
    height: 1px;
    border: none;

    display: block;
    color: white;
    background: black;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0)), color-stop(50%, rgba(235, 235, 235, 1)));
  }

  &_faded-2 {
    position: relative;
    border: none;
    min-height: 10px;
    background-size: 100% 20px;

    &:after {
      background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03), transparent);
      content: '';
      display: block;
      height: 3px;

      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

a {
  color: #bbb;
  text-decoration: none;
  transition: .3s ease color;

  &:hover {
    color: #333;
  }
}

abbr[title] {
  border-bottom: 1px dotted #999;
  text-decoration: none;
}
/* @end */
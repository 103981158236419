@import 'mixins/fight-space';
@import 'mixins/padding';
@import 'mixins/header';
@import 'mixins/theme-color';

@import 'variables/themes';


/* @group header */
.header-wrapper {
  &_without-border {
    .header {
      .section {
        border-bottom-color: rgba(255, 255, 255, 9);
      }
    }
  }

  &_fade-border {
    .header {
      .section {
        border-bottom-color: rgba(255, 255, 255, .15);
      }
    }
  }

  &_dark {
    .header {
      @include header-dark;
      text-shadow: none;
    }
  }
}

.header {
  border-color: inherit;
  //background: rgba(255, 255, 255, .95);

  &__header-1 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
  }

  &__section-extra {
    @include vertical-padding(20px);

    border-right: none;
    border-left: none;
  }

  &__banner {
    @include fight-space;

    // margin-top: 10px; // value from sibling element
    // margin-bottom: 10px; // value from sibling element

    text-align: left; // especially required when this block wrapped with block with different align (etc center)

    &-logo {
      display: inline-block;
      height: 40px;
      width: 40px;

      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 10px;

      background-repeat: no-repeat;
      background-position: 0 center;
      background-size: 40px;

      vertical-align: middle;

      @include header-logo-base();

      /* @group themes */
      @each $themeName, $themeColor in $themes {
        /* @group #{$themeName} theme */
        .html_theme_#{$themeName} & {
          @include header-logo-theme($themeName);
          /* @end */
        }
      }
      /* @end */
    }

    &-first-name, &-last-name {
      display: inline-block;
      vertical-align: middle;

      font-size: 1rem;
      font-weight: 400;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    &-first-name {
      /* @group themes */
      @include theme-color;
      /* @end */
    }

    &-last-name {
      /** last name */
      color: #c8c8c8;
    }
  }

  &_dark {
    background: rgb(233, 233, 233);
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, .1) inset, 0 -10px 10px -10px rgba(0, 0, 0, .1) inset;
    color: #fff;
    text-shadow: none;
  }
}

.entity-detail-list {
  display: inline-block;

  &__detail {
    @include fight-space;

    color: rgb(68, 68, 68);
    margin-right: 10px;

    .fa { // dirty a bit
      margin-right: 5px;
      font-size: 14px;
      vertical-align: middle;
    }

    span { // dirty
      transition: color .2s ease;

      vertical-align: middle;
      font-size: 12px;
    }

    &:hover {
      span {
        color: #39f;
      }
    }
  }
}

.article-tag-list {
  float: right;

  .entity-detail-list__detail {
    margin-left: 10px;
    margin-right: 0;
  }
}
/* @end */

/* @group header navigation */

// Structure:
//  header-navigation
//   main
//    item
//     url
//  minimized-container
//   minimized

.header-navigation {
  text-align: right;

  &__main { // ul
    @include fight-space;

    padding: 0;
    list-style-type: none;

    white-space: nowrap;

    font-weight: 100;
    line-height: 50px;
  }

  &__item {
    display: inline-block;
    margin-left: 20px;
    line-height: inherit;
  }

  &__url {
    display: inline-block;
    font-size: 1rem;
    line-height: inherit;

    transition: color .2s ease, border-bottom-color .2s ease !important;
    text-decoration: none;

    color: #444 ;
    border-bottom: 2px solid rgba(29, 143, 0, 0);

    padding-top: 10px;
    padding-bottom: 8px; // ok, 2px from border-bottom

    .header-wrapper_dark & {
      color: white !important;
      text-shadow: 1px 0 0 rgba(0, 0, 0, .3);
    }
  }

  &__minimized-container {
    &::before {
      content: '';
      height: 70px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__minimized {
    display: inline-block;
    vertical-align: middle;
  }
}
/* @end*/

/* @group themes */
@each $themeName, $themeColor in $themes {
  /* @group #{$themeName} theme */
  .html_theme_#{$themeName} .header-navigation__url {
    &:hover, &_active {
      color: #{$themeColor} !important;
      border-bottom-color: #{$themeColor};
    }
  }
  /* @end */
}
/* @end */
// URL to base font directory
$pt-sans-font-path: "../fonts" !default;

// @font-face mixin

@mixin pt-sans-font-face( $font, $weight: normal, $style: normal, $installedNames: ()) {
  @font-face {
    $localFont: '';
    @each $installedName in $installedNames {
      $localFont: $localFont + ("local('" + $installedName + "'), ");
    }

    font-family: 'PT Sans';
    //src: url('#{$pt-sans-font-path}/pt-sans-#{$font}-webfont.eot');
    //src: url('#{$pt-sans-font-path}/pt-sans-#{$font}-webfont.eot?#iefix') format('embedded-opentype'),
    //url('#{$pt-sans-font-path}/pt-sans-#{$font}-webfont.woff') format('woff'),
    //url('#{$pt-sans-font-path}/pt-sans-#{$font}-webfont.ttf') format('truetype'),
    //url('#{$pt-sans-font-path}/pt-sans-#{$font}-webfont.svg##{$svgid}') format('svg');

    src: unquote($localFont) url('#{$pt-sans-font-path}/pt-sans-#{$font}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}

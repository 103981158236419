/* @group Link list block */
.link-list-block {
  display: block;
  padding: 20px 0;
  margin: 0;

  text-align: center;
  font-size: 16px;

  &__item {
    display: inline-block;
    margin: 0 10px;
  }
}
/* @end */
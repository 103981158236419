@charset "UTF-8";

/*!
 *  Original by github.com
 *  Extended by Михаил Драгункин
 *
 *  @version  1.0.0
 *  @todo rewrite to bem using scss
 */

.tooltipped {
  position: relative;
}

/*e*/
.tooltipped:after,
.tooltipped:before {
  transition: .3s ease opacity .4s;
  opacity: 0;
}

.tooltipped:after {
  position: absolute;
  z-index: 1000000;
  /**
  display: none;
*/
  /**e*/
  visibility: hidden;

  padding: 5px 8px;

  content: attr(aria-label);
  text-align: center;
  white-space: pre;
  text-decoration: none;
  letter-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  pointer-events: none;

  color: #fff;
  border-radius: 3px;
  background: rgba(0, 0, 0, .8);
  text-shadow: none;

  font: normal normal 11px/1.5 Helvetica, arial, nimbussansl, liberationsans, freesans, clean, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  -webkit-font-smoothing: subpixel-antialiased;
}

.tooltipped:before {
  position: absolute;
  z-index: 1000001;
  /**
  display: none;
*/
  /**e*/
  visibility: hidden;

  width: 0;
  height: 0;

  content: '';
  pointer-events: none;

  color: rgba(0, 0, 0, .8);
  border: 5px solid transparent;
}

.tooltipped:hover:before,
.tooltipped:hover:after,
.tooltipped:active:before,
.tooltipped:active:after,
.tooltipped:focus:before,
.tooltipped:focus:after {
  /**
  display: inline-block;
*/
  /**e*/
  visibility: visible;

  text-decoration: none;
  /*e*/

  opacity: 1;
}

.tooltipped-multiline:hover:after,
.tooltipped-multiline:active:after,
.tooltipped-multiline:focus:after {
  display: table-cell;
}

.tooltipped-s:after,
.tooltipped-se:after,
.tooltipped-sw:after {
  top: 100%;
  right: 50%;

  margin-top: 5px;
}

.tooltipped-s:before,
.tooltipped-se:before,
.tooltipped-sw:before {
  top: auto;
  right: 50%;
  bottom: -5px;

  margin-right: -5px;

  border-bottom-color: rgba(0, 0, 0, .8);
}

.tooltipped-se:after {
  right: auto;
  left: 50%;

  margin-left: -15px;
}

.tooltipped-sw:after {
  margin-right: -15px;
}

.tooltipped-n:after,
.tooltipped-ne:after,
.tooltipped-nw:after {
  right: 50%;
  bottom: 100%;

  margin-bottom: 5px;
}

.tooltipped-n:before,
.tooltipped-ne:before,
.tooltipped-nw:before {
  top: -5px;
  right: 50%;
  bottom: auto;

  margin-right: -5px;

  border-top-color: rgba(0, 0, 0, .8);
}

.tooltipped-ne:after {
  right: auto;
  left: 50%;

  margin-left: -15px;
}

.tooltipped-nw:after {
  margin-right: -15px;
}

.tooltipped-s:after,
.tooltipped-n:after {
  -webkit-transform: translateX(50%);
  transform: translateX(50%);
}

.tooltipped-w:after {
  right: 100%;
  bottom: 50%;

  margin-right: 5px;

  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.tooltipped-w:before {
  top: 50%;
  bottom: 50%;
  left: -5px;

  margin-top: -5px;

  border-left-color: rgba(0, 0, 0, .8);
}

.tooltipped-e:after {
  bottom: 50%;
  left: 100%;

  margin-left: 5px;

  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}

.tooltipped-e:before {
  top: 50%;
  right: -5px;
  bottom: 50%;

  margin-top: -5px;

  border-right-color: rgba(0, 0, 0, .8);
}

.tooltipped-multiline:after {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 250px;

  white-space: pre-line;
  word-wrap: normal;
  word-break: break-word;

  border-collapse: separate;
}

.tooltipped-multiline.tooltipped-s:after,
.tooltipped-multiline.tooltipped-n:after {
  right: auto;
  left: 50%;

  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.tooltipped-multiline.tooltipped-w:after,
.tooltipped-multiline.tooltipped-e:after {
  right: 100%;
}

@media screen and (min-width: 0\0) {
  .tooltipped-multiline:after {
    width: 250px;
  }
}

.tooltipped-sticky:before,
.tooltipped-sticky:after {
  display: inline-block;
}

.tooltipped-sticky.tooltipped-multiline:after {
  display: table-cell;
}
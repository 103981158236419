// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$dotudash-logo-black-40x40-name: 'dotudash-logo_black_40x40';
$dotudash-logo-black-40x40-x: 0px;
$dotudash-logo-black-40x40-y: 0px;
$dotudash-logo-black-40x40-offset-x: 0px;
$dotudash-logo-black-40x40-offset-y: 0px;
$dotudash-logo-black-40x40-width: 40px;
$dotudash-logo-black-40x40-height: 40px;
$dotudash-logo-black-40x40-total-width: 280px;
$dotudash-logo-black-40x40-total-height: 40px;
$dotudash-logo-black-40x40-image: 'dotudash-logo.png';
$dotudash-logo-black-40x40: (0px, 0px, 0px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_black_40x40', );
$dotudash-logo-blue-40x40-name: 'dotudash-logo_blue_40x40';
$dotudash-logo-blue-40x40-x: 40px;
$dotudash-logo-blue-40x40-y: 0px;
$dotudash-logo-blue-40x40-offset-x: -40px;
$dotudash-logo-blue-40x40-offset-y: 0px;
$dotudash-logo-blue-40x40-width: 40px;
$dotudash-logo-blue-40x40-height: 40px;
$dotudash-logo-blue-40x40-total-width: 280px;
$dotudash-logo-blue-40x40-total-height: 40px;
$dotudash-logo-blue-40x40-image: 'dotudash-logo.png';
$dotudash-logo-blue-40x40: (40px, 0px, -40px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_blue_40x40', );
$dotudash-logo-green-40x40-name: 'dotudash-logo_green_40x40';
$dotudash-logo-green-40x40-x: 80px;
$dotudash-logo-green-40x40-y: 0px;
$dotudash-logo-green-40x40-offset-x: -80px;
$dotudash-logo-green-40x40-offset-y: 0px;
$dotudash-logo-green-40x40-width: 40px;
$dotudash-logo-green-40x40-height: 40px;
$dotudash-logo-green-40x40-total-width: 280px;
$dotudash-logo-green-40x40-total-height: 40px;
$dotudash-logo-green-40x40-image: 'dotudash-logo.png';
$dotudash-logo-green-40x40: (80px, 0px, -80px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_green_40x40', );
$dotudash-logo-lightblue-40x40-name: 'dotudash-logo_lightblue_40x40';
$dotudash-logo-lightblue-40x40-x: 120px;
$dotudash-logo-lightblue-40x40-y: 0px;
$dotudash-logo-lightblue-40x40-offset-x: -120px;
$dotudash-logo-lightblue-40x40-offset-y: 0px;
$dotudash-logo-lightblue-40x40-width: 40px;
$dotudash-logo-lightblue-40x40-height: 40px;
$dotudash-logo-lightblue-40x40-total-width: 280px;
$dotudash-logo-lightblue-40x40-total-height: 40px;
$dotudash-logo-lightblue-40x40-image: 'dotudash-logo.png';
$dotudash-logo-lightblue-40x40: (120px, 0px, -120px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_lightblue_40x40', );
$dotudash-logo-original-40x40-name: 'dotudash-logo_original_40x40';
$dotudash-logo-original-40x40-x: 160px;
$dotudash-logo-original-40x40-y: 0px;
$dotudash-logo-original-40x40-offset-x: -160px;
$dotudash-logo-original-40x40-offset-y: 0px;
$dotudash-logo-original-40x40-width: 40px;
$dotudash-logo-original-40x40-height: 40px;
$dotudash-logo-original-40x40-total-width: 280px;
$dotudash-logo-original-40x40-total-height: 40px;
$dotudash-logo-original-40x40-image: 'dotudash-logo.png';
$dotudash-logo-original-40x40: (160px, 0px, -160px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_original_40x40', );
$dotudash-logo-purple-40x40-name: 'dotudash-logo_purple_40x40';
$dotudash-logo-purple-40x40-x: 200px;
$dotudash-logo-purple-40x40-y: 0px;
$dotudash-logo-purple-40x40-offset-x: -200px;
$dotudash-logo-purple-40x40-offset-y: 0px;
$dotudash-logo-purple-40x40-width: 40px;
$dotudash-logo-purple-40x40-height: 40px;
$dotudash-logo-purple-40x40-total-width: 280px;
$dotudash-logo-purple-40x40-total-height: 40px;
$dotudash-logo-purple-40x40-image: 'dotudash-logo.png';
$dotudash-logo-purple-40x40: (200px, 0px, -200px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_purple_40x40', );
$dotudash-logo-red-40x40-name: 'dotudash-logo_red_40x40';
$dotudash-logo-red-40x40-x: 240px;
$dotudash-logo-red-40x40-y: 0px;
$dotudash-logo-red-40x40-offset-x: -240px;
$dotudash-logo-red-40x40-offset-y: 0px;
$dotudash-logo-red-40x40-width: 40px;
$dotudash-logo-red-40x40-height: 40px;
$dotudash-logo-red-40x40-total-width: 280px;
$dotudash-logo-red-40x40-total-height: 40px;
$dotudash-logo-red-40x40-image: 'dotudash-logo.png';
$dotudash-logo-red-40x40: (240px, 0px, -240px, 0px, 40px, 40px, 280px, 40px, 'dotudash-logo.png', 'dotudash-logo_red_40x40', );
$spritesheet-width: 280px;
$spritesheet-height: 40px;
$spritesheet-image: 'dotudash-logo.png';
$spritesheet-sprites: ($dotudash-logo-black-40x40, $dotudash-logo-blue-40x40, $dotudash-logo-green-40x40, $dotudash-logo-lightblue-40x40, $dotudash-logo-original-40x40, $dotudash-logo-purple-40x40, $dotudash-logo-red-40x40, );
$spritesheet: (280px, 40px, 'dotudash-logo.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

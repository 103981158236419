/* @group uncensore extensions */
/* Censored element stylization */
[data-censore] {
  border-bottom: 1px rgba(0, 0, 0, .12) dotted;

  body[data-uncensore] & {
    background: rgba(255, 0, 0, .08);
    cursor: pointer;
    transition: background-color .3s ease;
    border-bottom-color: transparent;
  }
}

/* Other element stylization depending on uncensore application state */
.censore-block {
  body[data-uncensore] & {
    color: red;
  }
}
/* @end */
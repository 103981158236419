// `theme_name` : `main_color`
$themes:
  ( 'red'         : #ff0000
  , 'orange'      : #ffa500
  , 'purple'      : #7400bf
  , 'green'       : #08bb33
  //, 'java'        : #199A91
  , 'java'        : #00aaaa
  , 'lightblue'   : #0070df
  , 'black'       : #000000
  );
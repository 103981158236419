@charset "UTF-8";

/*!
 *  Dotudash frontend framework.
 *
 *  @author   Михаил Драгункин <contact@md.land>
 *  @web      https://md.land/
 *  @version  1.0.0
 *  @since    September 11, 2016
 *
 *  Made with love.
 */

/* @group dotudash project */

/* @group normalize.css */
@import '../../../bower_components/normalize.css/normalize';
/* @end */

/* @group layout */
@import 'layout';

@import 'utilities/font';
@import 'utilities/text';
/* @end */

/* @group fonts */
@import 'fonts';
/* @end */

/* @group main styles */
@import 'base';
@import 'header';
@import 'footer';

@import 'interface/jumbotron';
@import 'interface/social-media-block';
@import 'interface/link-list-block';
@import 'interface/button';
@import 'interface/breadcrumb-navigation';
@import 'interface/colorful';
/* @end */

/* @group vendor components */

/* @group Animate.css */
@import '../../../bower_components/animate.css/animate';
/* @end */

/* @group Animate.css extension */
@import '../../components/unsektor/animate-extension/default';
/* @end */

/* @group tooltipped */
@import '../../components/unsektor/tooltipped/default';
/* @end */

/* @end */

/* @group bridge */
@import 'bridge/uncensore';
// @import 'bridge/dev';
/* @end */

/* @end */

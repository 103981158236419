@import '../mixins/button';
@import '../mixins/font';
@import '../mixins/padding';


/* @group button */
// todo decide to remove font mixins from button theme - it's a dirty
.button-list {
  @include fight-space;
  @include vertical-padding(5px);

  &_use-dark-bg {}
}

.button {
  @include horizontal-padding(15px);
  display: inline-block;

  font-size: 1rem;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 38px;

  min-width: 200px;
  max-width: 250px;

  height: 40px;

  margin: 5px;


  border: 1px solid transparent;
  border-radius: 4px;

  cursor: pointer;
  transition: .4s ease opacity, .25s ease background-color, .25s ease border-color;

  text-align: center;
  text-shadow: none;

  box-shadow: 0 1px 0 rgba(255, 255, 255, .3);

  &_theme {
    &_destroy-ep { // todo decide to remove pure `DIE` code
      @include font-light;
      background: #606c88;
      background: -moz-linear-gradient(-45deg, #606c88 0%, #3f4c6b 100%);
      background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, #606c88), color-stop(100%, #3f4c6b));
      background: -webkit-linear-gradient(-45deg, #606c88 0%, #3f4c6b 100%);
      background: -o-linear-gradient(-45deg, #606c88 0%, #3f4c6b 100%);
      background: -ms-linear-gradient(-45deg, #606c88 0%, #3f4c6b 100%);
      background: linear-gradient(135deg, #606c88 0%, #3f4c6b 100%);

      &:hover {
        @include font-light;
      }
    }

    &_ghost {
      @include button-theme(rgb(197, 203, 214), darken(rgb(197, 203, 214), 50%));
      color: rgb(72, 76, 85);
      //border-color: rgb(172, 179, 191);
      //background: rgb(197, 203, 214);
      //text-shadow: 1px 1px 0 rgba(255, 255, 255, .5);
    }

    &_java {
      @include button-theme(#199a95, darken(#199a95, 15%));
      @include font-from-background-color(#199a95);
    }

    &_summer-sky {
      @include button-theme(#3d9de1, darken(#3d9de1, 10%)); // dark 30
      @include font-from-background-color(#3d9de1);

      &:hover {
        color: #fff !important;
      }
    }

    &_fern--christi { //
      @include button-theme(#508c00, darken(#508c00, 10%)); // dark 15
      @include font-from-background-color(#508c00);
      //border: 1px solid rgba(0, 0, 0, .1);
      /* Rectangle 105: */
      //background-image: linear-gradient(0deg, #508c00 0%, #64b400 100%);
      /* Sketch doesnt export gradient borders at this point */
      //box-shadow: 0 0 1px 0 #fff inset;
      //text-shadow: 1px 1px 1px rgba(0, 0, 0, .4);
    }

    &_forest-green {
      // #0a9f17

      @include button-theme(#0bb71b, darken(#0bb71b, 10%)); // dark 20
      @include font-from-background-color(#0bb71b);
    }

    &_pastel-green {
      @include font-light;
      //text-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
      background: linear-gradient(to bottom, #63BA6C 0%, #78C365 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      &:hover {
        background: linear-gradient(to bottom, #70CE78 0%, #85D770 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }

    &_deep-sky-blue--pacific-blue { //
      @include font-light;
      // Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00b7ea+0,009ec3+100;Blue+3D+%2315
      background: #00b7ea; /* Old browsers */
      background: -moz-linear-gradient(top, #00b7ea 0%, #009ec3 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #00b7ea 0%, #009ec3 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #00b7ea 0%, #009ec3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      border-color: darken(#13c0d7, 10%); // dark 25
    }

    &_clean {
      @include font-dark;
      border-color: #ccc;
      background: transparent;
    }

    &_ou-crimson-red {
      @include button-theme(#9b0000, darken(#9b0000, 10%));
      @include font-from-background-color(#9b0000);
    }

    &_monsoon {
      @include button-theme(#777, darken(#777, 20%));
      @include font-from-background-color(#777);
    }

    &_clean-blue { // todo test
      @include button-theme(transparent, #0070df);
      color: #0070df;
    }
  }

  & .fa { // dirty a bit
    font-size: 14px;
    line-height: 38px;
    margin-right: 6px;
  }
}
/* @end */
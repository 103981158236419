/* @group open-sans font */
$open-sans-path: './fonts/open-sans';

@import '../../../bower_components/open-sans/scss/base';
@import '../../../bower_components/open-sans/scss/light';
@import '../../../bower_components/open-sans/scss/regular';
@import '../../../bower_components/open-sans/scss/semibold';
@import '../../../bower_components/open-sans/scss/bold';
/* @end */

/* @group font-awesome font */
$pt-sans-font-path: './fonts/pt-sans';
@import '../../components/unsektor/pt-sans/scss/pt-sans';
/* @end */

/* @group font-awesome font */
$fa-font-path: './fonts/font-awesome';
@import '../../../bower_components/font-awesome/scss/font-awesome';
/* @end */
@import '../mixins/theme-color';


/* @group breadcrumb-navigation */
.breadcrumb-navigation {
  padding: 10px 0; // ok
  margin: 0; // ok

  &__item {
    display: inline-block;
    font-family: 'PT Sans', 'Open Sans', 'Myriad Pro', 'Muna', 'Gill Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;

    transition: color .3s ease;

    &:last-child { // dirty
      font-weight: bold;
    }

    a { // dirty
      @include theme-color;
    }
  }

  &__separator {
    margin-left: 7px;
    margin-right: 7px;
    color: #D2D2D2;
  }
}
/* @end */
@import '../mixins/font';


/* @group font globals */
.g-font {
  &_light {
    @include font-light;
  }

  &_dark {
    @include font-dark;
  }
}
/* @end */
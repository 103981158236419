@import '../mixins/padding';
@import '../../../../bower_components/bootstrap/scss/variables';
@import '../../common/variables/bootstrap-grid';
@import '../../../../bower_components/bootstrap/scss/mixins/breakpoints';


/* @group jumbotron */
.jumbotron {
  $self: &;

  @include vertical-padding(75px);

  text-align: center;

  font: {
    size: 28px;
    weight: 300; // fix after normalization
  }

  line-height: 38px;

  &_size { // todo choose one: a, b, c; s; m; l, xl, xxl; small, big, biggest, large, largest; ...
    &_a { @include vertical-padding(35px !important); }
    &_b {
      @include vertical-padding(50px);

      @include media-breakpoint-down('md', $grid-breakpoints) {
        @include vertical-padding(20px !important);
        font-size: 24px;
        line-height: 30px;
      }

      @include media-breakpoint-down('sm', $grid-breakpoints) {
        @include vertical-padding(20px !important);
        font-size: 20px;
        line-height: 26px;
      }
    }
    &_c { @include vertical-padding(75px !important); }
    &_d { @include vertical-padding(100px !important); }
    &_e { @include vertical-padding(125px !important); }
    &_f { @include vertical-padding(150px !important); }
  }

  &_faded {
    // warning: influences on position property
    position: relative; // may be dirty

    &::before, &::after {
      width: 100%;
      position: absolute;
      display: block;
      content: '';
      left: 0;
      height: 8px;
    }

    &::before {
      top: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,.09), rgba(0,0,0,0) 8px);
    }

    &::after {
      bottom: 0;
      background: linear-gradient(to top, rgba(0,0,0,.09), rgba(0,0,0,0) 8px);
    }
    // background: linear-gradient(to bottom, rgba(0,0,0,.09), rgba(0,0,0,0) 8px), linear-gradient(to top, rgba(0,0,0,.09), rgba(0,0,0,0) 8px);
  }

  &__soft {
    display: block;
    color: #C8C8C8;
    font-size: 0.8571428571em;  // 24/28
  }

  b {  // dirty, but ok - fix after normalization
    font-weight: 600;
  }

  & {
    #{$self}__paragraph {
      $vertical-margin: 37.5px;

      margin-top: #{$vertical-margin};
      margin-bottom: #{$vertical-margin};

      ~ #{$self}__paragraph {
        margin-top: #{$vertical-margin * 2};
      }
    }
  }
}
/* @end */
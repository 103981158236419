$grid-columns:      24;
$grid-gutter-width: 20px;

$grid-breakpoints:
  ( xs: 0
  , sm: 544px
  , md: 768px
  , lg: 992px
  , xl: 1200px
  );

$container-max-widths:
  ( sm: 576px
  , md: 720px
  , lg: 960px // default is 940
  );
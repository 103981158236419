// this is similar to bootstrap
@mixin button-theme($backgroundColor, $borderColor) {
  $active-background: darken($backgroundColor, 5%);
  $active-border: darken($borderColor, 6%);

  box-shadow: 0 2px 0px -1px rgba(255, 255, 255, .3) inset, 0 1px 0 rgba(255, 255, 255, .2);

  background-color: $backgroundColor;
  border-color: $borderColor;

  &:hover {
    background-color: $active-background;
    border-color: $active-border;
  }
}